<template>
  <div>
    <SpinnerLogo v-if="loading" class="p-5" />
    <Pages v-else title="CXG Summary">
      <PageLandscape no-header no-copyright>
        <div class="etn-report">
          <!-- Header Summary Box START -->
          <div class="bg-lighter rounded-md mb-4 p-4">
            <div class="row align-items-middle">
              <div class="col-md-4">
                <PageHeader>
                  <template #top>
                    <span v-if="period.startDate.format('MMM YYYY') == period.endDate.format('MMM YYYY')">{{
                      period.startDate | date('MMM YYYY')
                    }}</span>
                    <span v-else>{{ period.startDate | date('MMM YYYY') }} to {{ period.endDate | date('MMM YYYY') }}</span>
                  </template>
                  <template #title>Meter Management</template>
                  <template #bottom>{{ asset?.siteName || 'N/A' }}</template>
                </PageHeader>
              </div>

              <div class="col-md-3">
                <div class="asset-info">
                  <PropertyListItem col-size="col-md-4" class="mb-2 font-w600" title="Company">{{
                    ultimateParentEntity?.legalName || 'N/A'
                  }}</PropertyListItem>
                  <PropertyListItem col-size="col-md-4" class="mb-2 font-w600" title="Site">{{ asset?.siteName || 'N/A' }}</PropertyListItem>
                  <PropertyListItem col-size="col-md-4" class="mb-2 font-w600" title="Meter">{{
                    account?.meterPointNumber || 'N/A'
                  }}</PropertyListItem>
                </div>
              </div>

              <div class="col-md-3">
                <div class="asset-info">
                  <PropertyListItem col-size="col-md-4" class="mb-2 font-w600" title="Period From">{{
                    period.startDate || 'N/A' | date('DD MMMM YYYY')
                  }}</PropertyListItem>
                  <PropertyListItem col-size="col-md-4" class="mb-2 font-w600" title="Period To">{{
                    period.endDate || 'N/A' | date('DD MMMM YYYY')
                  }}</PropertyListItem>
                  <PropertyListItem col-size="col-md-4" class="mb-2 font-w600" title="Units">{{ friendlyMeterUnit }}</PropertyListItem>
                </div>
              </div>

              <!-- <div class="col-md-3">
                <PropertyListItem col-size="col-md-4" class="mb-2 font-w600" title="Period From">1st March 2023</PropertyListItem>
                <PropertyListItem col-size="col-md-4" class="font-w600" title="Period To">1st March 2024</PropertyListItem>
              </div> -->

              <div class="col-md-2 text-right">
                <img class="logo" :src="company.settings.reportLogoUrl || company.settings.logoUrl" />
              </div>
            </div>
          </div>
          <!-- Header Summary Box END -->

          <!-- Monthly Charges START -->
          <div v-if="analytics.consumption.accountId">
            <h5 class="border-bottom pb-2">Monthly Charges</h5>
            <div class="row">
              <div class="col-md-5">
                <HHCapacityInformation
                  :period="period"
                  :data="analytics.consumption"
                  :prev-data="analytics.consumptionPrevYear"
                ></HHCapacityInformation>
              </div>
              <div class="col-md-7">
                <AccountDailyConsumption
                  class="mb-2 border-bottom"
                  :period="period"
                  :data="analytics.consumption.data"
                  :units="friendlyMeterUnit"
                  style="height: 350px"
                />
                <AccountHourlyDemand style="height: 350px" :period="period" :data="analytics.consumption.data" :units="friendlyMeterUnit" />
              </div>
            </div>
          </div>
          <!-- Monthly Charges END -->
        </div>
      </PageLandscape>
      <PageLandscape no-header no-copyright>
        <div class="etn-report">
          <!-- Calendar Sparklines START -->
          <div v-if="analytics.consumption.accountId">
            <h5 class="border-bottom pb-2">Monthly Meter Profile</h5>
            <table class="calendar w-100 mb-3">
              <thead>
                <tr>
                  <th width="14.25%">Monday</th>
                  <th width="14.25%">Tuesday</th>
                  <th width="14.25%">Wednesday</th>
                  <th width="14.25%">Thursday</th>
                  <th width="14.25%">Friday</th>
                  <th width="14.25%">Saturday</th>
                  <th width="14.25%">Sunday</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(chartRow, idx) in consumptionCalendarCharts" :key="idx">
                  <td>
                    <div v-if="chartRow.some(item => item.weekdayIndex === 1)">
                      <Chart svg style="height: 100px" :option="chartRow.find(item => item.weekdayIndex === 1)" />
                    </div>
                  </td>
                  <td>
                    <div v-if="chartRow.some(item => item.weekdayIndex === 2)">
                      <Chart svg style="height: 100px" :option="chartRow.find(item => item.weekdayIndex === 2)" />
                    </div>
                  </td>
                  <td>
                    <div v-if="chartRow.some(item => item.weekdayIndex === 3)">
                      <Chart svg style="height: 100px" :option="chartRow.find(item => item.weekdayIndex === 3)" />
                    </div>
                  </td>
                  <td>
                    <div v-if="chartRow.some(item => item.weekdayIndex === 4)">
                      <Chart svg style="height: 100px" :option="chartRow.find(item => item.weekdayIndex === 4)" />
                    </div>
                  </td>
                  <td>
                    <div v-if="chartRow.some(item => item.weekdayIndex === 5)">
                      <Chart svg style="height: 100px" :option="chartRow.find(item => item.weekdayIndex === 5)" />
                    </div>
                  </td>
                  <td>
                    <div v-if="chartRow.some(item => item.weekdayIndex === 6)">
                      <Chart svg style="height: 100px" :option="chartRow.find(item => item.weekdayIndex === 6)" />
                    </div>
                  </td>
                  <td>
                    <div v-if="chartRow.some(item => item.weekdayIndex === 0)">
                      <Chart svg style="height: 100px" :option="chartRow.find(item => item.weekdayIndex === 0)" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row justify-content-end">
              <div class="col-md-6">
                <div class="bg-light rounded-md px-4 py-3">
                  <PropertyListItem col-size="col-md-6" class="mb-2 font-w600" title="Total Consumption"
                    >{{ analytics.consumption.totalConsumption | numberFormat(2)
                    }}<span class="text-muted small ml-1">{{ friendlyMeterUnit }}</span></PropertyListItem
                  >
                  <PropertyListItem col-size="col-md-6" class="mb-2 font-w600" title="This month daily average"
                    ><div class="d-flex align-baseline">
                      {{ currentMonthDailyAverage | numberFormat(2) }}<span class="text-muted small ml-1">{{ friendlyMeterUnit }}</span
                      ><PctDiff
                        class="ml-2"
                        :value="currentMonthDailyAverage"
                        :prev-value="lastMonthDailyAverage"
                        comparison-period="year"
                        hide-text
                        precision="2"
                      /></div
                  ></PropertyListItem>
                  <PropertyListItem col-size="col-md-6" class="mb-2 font-w600" title="Last month daily average"
                    >{{ lastMonthDailyAverage | numberFormat(2) }}<span class="text-muted small ml-1">{{ friendlyMeterUnit }}</span></PropertyListItem
                  >
                  <PropertyListItem v-if="peakConsumptionItem" col-size="col-md-6" class="font-w600" title="Peak consumption"
                    >{{ peakConsumptionItem.consumption | numberFormat(2) }}<span class="text-muted small ml-1">{{ friendlyMeterUnit }}</span> ({{
                      peakConsumptionItem.date | date('DD MMMM YYYY HH:mm')
                    }})</PropertyListItem
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- Monthly Charges END -->
        </div>
      </PageLandscape>
      <PageLandscape no-header no-copyright>
        <div class="etn-report">
          <!-- Yearly Metering Summary START -->
          <div class="row">
            <div class="col-md-5">
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th>Month</th>
                    <th>Consumption</th>
                    <th>Cost</th>
                    <th>tCO2</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="monthData in monthlySummaryData" :key="monthData.date">
                    <td>{{ monthData.date | date('MMMM') }}</td>
                    <td class="text-right">
                      <span v-if="monthData.consumption !== null"> {{ monthData.consumption | numberFormat(0) }}</span
                      ><span v-else>N/A</span>
                    </td>
                    <td class="text-right">£{{ monthData.cost | numberFormat(2) }}</td>
                    <td class="text-right">
                      <span v-if="monthData.emissions !== null"> {{ monthData.emissions | numberFormat(2) }}</span
                      ><span v-else>N/A</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Total</th>
                    <th class="text-right">
                      <span v-if="analytics.consumptionYearly.totalConsumption !== null">
                        {{ analytics.consumptionYearly.totalConsumption | numberFormat(2) }}</span
                      ><span v-else>N/A</span>
                    </th>
                    <th class="text-right">£{{ monthlySummaryData.reduce((acc, item) => acc + item.cost, 0) | numberFormat(2) }}</th>
                    <th class="text-right">
                      <span v-if="analytics.consumptionYearly.totalEmissions.total !== null">
                        {{ (analytics.consumptionYearly.totalEmissions.total / 1000) | numberFormat(2) }}</span
                      ><span v-else>N/A</span>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-7">
              <Chart svg class="mb-4" style="height: 350px" :option="yearlyConsumptionChart" />
              <Chart svg class="mb-4" style="height: 350px" :option="yearlyCostChart" />
            </div>
          </div>
          <!-- Yearly Metering Summary END -->
        </div>
      </PageLandscape>
      <PageLandscape no-header no-copyright>
        <div class="etn-report">
          <!-- Yearly Comparison START -->
          <Chart svg class="mb-4" style="height: 350px" :option="yearlyComparisonChart" />
          <div class="row justify-content-center">
            <div class="col-md-10">
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th rowspan="2">Month</th>
                    <th colspan="3">Consumption</th>
                    <th colspan="3">Cost</th>
                  </tr>
                  <tr>
                    <th>This Year</th>
                    <th>Last Year</th>
                    <th>%</th>
                    <th>This Year</th>
                    <th>Last Year</th>
                    <th>%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="monthData in monthlySummaryData" :key="monthData.date">
                    <td>{{ monthData.date | date('MMMM') }}</td>
                    <td class="text-right">
                      <span v-if="monthData.consumption !== null"> {{ monthData.consumption | numberFormat(0) }}</span
                      ><span v-else>N/A</span>
                    </td>
                    <td class="text-right">
                      <span v-if="monthData.prevConsumption !== null"> {{ monthData.prevConsumption | numberFormat(0) }}</span
                      ><span v-else>N/A</span>
                    </td>
                    <td>
                      <PctDiff :value="monthData.consumption" :prev-value="monthData.prevConsumption" comparison-period="year" hide-text />
                    </td>
                    <td class="text-right">£{{ monthData.cost | numberFormat(2) }}</td>
                    <td class="text-right">£{{ monthData.prevCost | numberFormat(2) }}</td>
                    <td>
                      <PctDiff :value="monthData.cost" :prev-value="monthData.prevCost" comparison-period="year" hide-text />
                    </td>
                  </tr>
                  <tr>
                    <th>Total</th>
                    <th class="text-right">{{ monthlySummaryData.reduce((acc, item) => acc + item.consumption, 0) | numberFormat(2) }}</th>
                    <th class="text-right">{{ monthlySummaryData.reduce((acc, item) => acc + item.prevConsumption, 0) | numberFormat(2) }}</th>
                    <th class="text-right"></th>
                    <th class="text-right">£{{ monthlySummaryData.reduce((acc, item) => acc + item.cost, 0) | numberFormat(2) }}</th>
                    <th class="text-right">£{{ monthlySummaryData.reduce((acc, item) => acc + item.prevCost, 0) | numberFormat(2) }}</th>
                    <th class="text-right"></th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- Yearly Comparison END -->
      </PageLandscape>
    </Pages>
  </div>
</template>
<script>
import { merge } from 'lodash';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import Chart from '@/components/echarts/Chart';
import chartOptions from './charts/options';

import PctDiff from '@/components/analytics/PctDiff';
import SpinnerLogo from '@/components/SpinnerLogo';
import PageHeader from './components/PageHeader';
import PageLandscape from './components/PageLandscape';
import Pages from './components/Pages';
import PropertyListItem from '@/components/base/PropertyListItem';

import HHCapacityInformation from './tables/HHCapacityInformation';
import AccountDailyConsumption from './charts/AccountDailyConsumption';
import AccountHourlyDemand from './charts/AccountHourlyDemand';

export default {
  name: 'AssetSummaryPDF',
  components: {
    AccountDailyConsumption,
    AccountHourlyDemand,
    Chart,
    HHCapacityInformation,
    PageHeader,
    PageLandscape,
    Pages,
    PctDiff,
    PropertyListItem,
    SpinnerLogo
  },
  data() {
    return {
      error: '',
      loading: true,
      period: {
        month: '2024-04',
        startDate: moment().subtract(1, 'year').startOf('year'),
        endDate: moment().subtract(1, 'year').endOf('year'),
        prevYearStartDate: moment().subtract(2, 'year').startOf('year'),
        prevYearEndDate: moment().subtract(2, 'year').endOf('year'),
        prevMonthStartDate: moment().subtract(1, 'month').startOf('month'),
        prevMonthEndDate: moment().subtract(1, 'month').endOf('month')
      },
      asset: null,
      ultimateParentEntity: null
    };
  },
  computed: {
    ...mapGetters({
      account: 'account/account',
      company: 'company/company',
      report: 'report/report',
      analytics: 'analytics/analytics',
      loadingAction: 'analytics/loadingAction',
      errorAction: 'analytics/errorAction',
      analyticsOptions: 'analytics/options',
      accountLoading: 'account/loadingAction',
      benchmarks: 'benchmark/benchmarks',
      assetGroupAssets: 'assetGroup/assets',
      invoices: 'invoice/invoices',
      units: 'util/units'
    }),
    baseChartOptions() {
      return {
        color: ['#65C198', '#1F303D', '#F25F5C', '#FFE066', '#1B98E0']
      };
    },
    friendlyMeterUnit() {
      if (this.units?.length > 0 && this.account?.meterUnits) {
        return this.units.find(u => u.value === this.account.meterUnits)?.name;
      }

      return 'N/A';
    },
    currentMonthDailyAverage() {
      return this.analytics.consumption.totalConsumption / parseInt(this.period.endDate.date(), 10);
    },
    lastMonthDailyAverage() {
      return this.analytics.consumptionPrevMonth.totalConsumption / parseInt(this.period.endDateMinusMonth.date(), 10);
    },
    peakConsumptionItem() {
      const maxValue = Math.max(...this.analytics.consumption.data.filter(item => item.consumption !== null).map(item => item.consumption));

      return this.analytics.consumption.data.find(item => item.consumption === maxValue);
    },
    consumptionCalendarCharts() {
      // All graphs should have identical axis, to allow for easier comparison. Use the highest value (+ 20% for padding)
      const maxValue = this.analytics.consumption.data.reduce((acc, item) => Math.max(acc, item.consumption), 0);

      const daysArray = Array.from({ length: moment(this.period.startDate).endOf('month').date() }, (_, i) =>
        moment(this.period.startDate).add(i, 'days')
      );

      const hhArray = Array.from({ length: 48 }, (_, i) => moment(this.period.startDate).add(i * 30, 'minutes'));

      const chartOptions = {
        animation: false,
        color: ['#65C198'],
        grid: {
          left: 0,
          right: 0,
          top: 5,
          bottom: 5,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: hhArray.map(day => day.format('HH:mm')),
          silent: true,
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: { show: false },
          show: false
        },
        yAxis: {
          type: 'value',
          silent: true,
          axisLine: { show: true },
          axisTick: { show: true, length: 3 },
          splitLine: { show: false },
          min: 0,
          max: Math.ceil(maxValue * 1.1)
        },
        legend: {
          show: false
        }
      };

      const weeksArray = [[]];

      daysArray.forEach(day => {
        const weekIndex = day.weekday();

        const dayData = this.analytics.consumption.data.filter(item => moment.utc(item.date).date() === day.date());

        const item = {
          date: day.date(),
          weekdayIndex: weekIndex,
          ...chartOptions,
          title: {
            text: `${day.date()}`,
            right: 0,
            top: '5%',
            textStyle: {
              fontSize: 14
            }
          },
          series: [
            {
              type: 'line',
              silent: true,
              data: dayData.map(item => item.consumption),
              areaStyle: {
                color: '#65C198'
              },
              lineStyle: {
                width: 0
              },
              symbol: 'none'
            }
          ]
        };

        if (this.peakConsumptionItem) {
          const isDayWithPeak = moment(this.peakConsumptionItem.date).format('YYYY-MM-DD') === day.format('YYYY-MM-DD');

          if (isDayWithPeak) {
            item.series[0].markLine = {
              symbol: ['none', 'none'],
              label: { show: false },
              data: [{ xAxis: moment(this.peakConsumptionItem.date).format('HH:mm') }],
              lineStyle: {
                color: '#F25F5C',
                type: 'dashed'
              }
            };
          }
        }

        if (weekIndex !== 1) {
          weeksArray[weeksArray.length - 1].push(item);
        } else {
          weeksArray.push([item]);
        }

        return;
      });

      return weeksArray;
    },
    yearlyConsumptionChart() {
      return merge({}, chartOptions, {
        xAxis: {
          type: 'category',
          data: this.monthlySummaryData.map(item => moment(item.date).format('MMM'))
        },
        yAxis: [
          {
            type: 'value',
            name: this.friendlyMeterUnit,
            alignTicks: true
          },
          {
            type: 'value',
            name: 'tCO2',
            alignTicks: true
          }
        ],
        series: [
          {
            name: 'Consumption',
            type: 'bar',
            data: this.monthlySummaryData.map(item => item.consumption)
          },
          {
            name: 'Emissions',
            type: 'line',
            yAxisIndex: 1,
            data: this.monthlySummaryData.map(item => item.emissions)
          }
        ],
        legend: {
          show: true
        }
      });
    },
    yearlyCostChart() {
      return merge({}, chartOptions, {
        xAxis: {
          type: 'category',
          data: this.monthlySummaryData.map(item => moment(item.date).format('MMM'))
        },
        yAxis: [
          {
            type: 'value',
            name: '£'
          }
        ],
        series: [
          {
            name: 'Cost',
            type: 'bar',
            data: this.monthlySummaryData.map(item => item.cost)
          }
        ],
        legend: {
          show: true
        }
      });
    },
    yearlyComparisonData() {
      return this.analytics.consumptionYearly.data.map(item => {
        const prevYear = this.analytics.consumptionYearlyPrev.data.find(prevItem => moment(prevItem.date).month() === moment(item.date).month());

        return {
          date: item.date,
          consumption: item.consumption,
          prevConsumption: prevYear?.consumption || 0,
          cost: item.costs,
          prevCost: prevYear?.costs || 0
        };
      });
    },
    monthlySummaryData() {
      return this.analytics.consumptionYearly.data.map(item => {
        const prevYear = this.analytics.consumptionYearlyPrev.data.find(prevItem => moment(prevItem.date).month() === moment(item.date).month());

        const costData = this.analytics.costsYearly.data.find(costItem => costItem.date === item.date);
        const prevCostData = this.analytics.costsYearlyPrev.data.find(costItem => costItem.date === prevYear?.date);

        return {
          date: item.date,
          consumption: item.consumption,
          cost: costData.cost,
          emissions: item.emissions.total / 1000,
          prevDate: prevYear?.date,
          prevConsumption: prevYear ? prevYear.consumption : 'N/A',
          prevEmissions: prevYear ? prevYear.emissions.total / 1000 : 'N/A',
          prevCost: prevYear ? prevCostData.cost : 'N/A'
        };
      });
    },
    yearlyComparisonChart() {
      return merge({}, chartOptions, {
        xAxis: {
          type: 'category',
          data: this.monthlySummaryData.map(item => moment(item.date).format('MMM'))
        },
        yAxis: [
          {
            type: 'value',
            name: this.friendlyMeterUnit,
            alignTicks: true
          },
          {
            type: 'value',
            name: 'Cost (£)',
            alignTicks: true
          }
        ],
        series: [
          {
            name: 'This Year Consumption',
            type: 'bar',
            data: this.monthlySummaryData.map(item => item.consumption)
          },
          {
            name: 'Last Year Consumption',
            type: 'bar',
            data: this.monthlySummaryData.map(item => item.prevConsumption)
          },
          {
            name: 'This Year Cost',
            type: 'line',
            yAxisIndex: 1,
            data: this.monthlySummaryData.map(item => item.cost)
          },
          {
            name: 'Last Year Cost',
            type: 'line',
            yAxisIndex: 1,
            data: this.monthlySummaryData.map(item => item.prevCost)
          }
        ],
        legend: {
          show: true
        }
      });
    }
  },
  async mounted() {
    const accountSource = this.report.metadata.sourceIds.find(id => id.type === 'account');
    if (!accountSource) this.errorRedirect('Missing Account ID in Report Metadata');

    const accountId = accountSource._id;

    this.listUnits();

    await this.getAccount({ id: accountId });
    if (!this.account?._id) this.errorRedirect('Account not found');

    this.ultimateParentEntity = await this.getUltimateParentEntity({ id: this.account.entityId });
    this.asset = this.account.asset;

    this.period.startDate = moment.utc(this.report.metadata.startDate);
    this.period.endDate = moment(this.period.startDate).endOf('month');

    this.period.startDateMinusYear = moment(this.period.startDate).subtract(1, 'year');
    this.period.endDateMinusYear = moment(this.period.startDateMinusYear).endOf('month');

    this.period.startDateMinusMonth = moment(this.period.startDate).subtract(1, 'month');
    this.period.endDateMinusMonth = moment(this.period.startDateMinusMonth).endOf('month');

    this.period.startDatePrevYear = moment(this.period.startDate).startOf('year').subtract(1, 'year');
    this.period.endDatePrevYear = moment(this.period.startDatePrevYear).endOf('year');

    this.period.startDateCurrentYear = moment(this.period.startDate).startOf('year');
    this.period.endDateCurrentYear = moment(this.period.startDate).endOf('year');

    this.getBenchmarks();

    const params = {
      startDate: this.period.startDate.format('YYYY-MM-DD'),
      endDate: this.period.endDate.format('YYYY-MM-DD'),
      granularity: 'halfhourly',
      dayNight: true,
      capacityInfo: true
    };

    await Promise.all([
      this.getAnalyticsV2({
        id: 'consumption',
        params: {
          id: 'consumption',
          ...params,
          dataType: 'account',
          accountId: this.account._id,
          companyId: this.account.companyId
        }
      }),
      this.getAnalyticsV2({
        id: 'consumptionPrevYear',
        params: {
          id: 'consumption',
          ...params,
          startDate: this.period.startDateMinusYear.format('YYYY-MM-DD'),
          endDate: this.period.endDateMinusYear.format('YYYY-MM-DD'),
          dataType: 'account',
          accountId: this.account._id,
          companyId: this.account.companyId
        }
      }),
      this.getAnalyticsV2({
        id: 'consumptionPrevMonth',
        params: {
          id: 'consumption',
          ...params,
          startDate: this.period.startDateMinusMonth.format('YYYY-MM-DD'),
          endDate: this.period.endDateMinusMonth.format('YYYY-MM-DD'),
          dataType: 'account',
          accountId: this.account._id,
          companyId: this.account.companyId
        }
      }),
      this.getAnalyticsV2({
        id: 'consumptionYearly',
        params: {
          id: 'consumption',
          ...params,
          granularity: 'monthly',
          emissions: true,
          startDate: this.period.startDateCurrentYear.format('YYYY-MM-DD'),
          endDate: this.period.endDateCurrentYear.format('YYYY-MM-DD'),
          dataType: 'account',
          accountId: this.account._id,
          companyId: this.account.companyId
        }
      }),
      this.getAnalyticsV2({
        id: 'consumptionYearlyPrev',
        params: {
          id: 'consumption',
          ...params,
          granularity: 'monthly',
          emissions: true,
          startDate: this.period.startDatePrevYear.format('YYYY-MM-DD'),
          endDate: this.period.endDatePrevYear.format('YYYY-MM-DD'),
          dataType: 'account',
          accountId: this.account._id,
          companyId: this.account.companyId
        }
      }),
      this.getAnalyticsV2({
        id: 'costsYearly',
        params: {
          id: 'consumption',
          ...params,
          showSimulated: true,
          granularity: 'monthly',
          source: 'invoice',
          financialInfo: 'full',
          startDate: this.period.startDateCurrentYear.format('YYYY-MM-DD'),
          endDate: this.period.endDateCurrentYear.format('YYYY-MM-DD'),
          dataType: 'account',
          accountId: this.account._id,
          companyId: this.account.companyId
        }
      }),
      this.getAnalyticsV2({
        id: 'costsYearlyPrev',
        params: {
          id: 'consumption',
          ...params,
          showSimulated: true,
          granularity: 'monthly',
          source: 'invoice',
          financialInfo: 'full',
          startDate: this.period.startDatePrevYear.format('YYYY-MM-DD'),
          endDate: this.period.endDatePrevYear.format('YYYY-MM-DD'),
          dataType: 'account',
          accountId: this.account._id,
          companyId: this.account.companyId
        }
      })
    ]);

    this.loading = false;
  },
  methods: {
    ...mapActions({
      getAccount: 'account/get',
      getAccountConsumption: 'account/getConsumptionOld',
      getAssetAccounts: 'asset/accounts',
      getAssetGroup: 'assetGroup/get',
      getReport: 'report/get',
      getBenchmarks: 'benchmark/list',
      getAnalytics: 'analytics/getAnalytics',
      getAssetGroupAssets: 'assetGroup/listAssets',
      getUltimateParentEntity: 'entity/ultimateParent',
      listUnits: 'util/listUnits',
      getAnalyticsV2: 'analytics/getAnalyticsV2',
      getInvoices: 'invoice/list'
    }),
    errorRedirect(reason) {
      this.$router.push({ name: 'machine-error', query: { errorCode: 500, errorData: { message: reason } } });
    }
  }
};
</script>
<style lang="scss" scoped>
.etn-report {
  font-size: 16px;

  table th,
  table td {
    padding: 0.3rem 0.5rem;
    height: 20px;
  }

  table thead th {
    background-color: #f0f3f8;
  }
}

table.calendar td {
  padding: 0.5rem 0.75rem;
}

.asset-image img {
  height: 100px;
  width: 100%;
  object-fit: cover;
}

.calendar-chart-item {
  width: 14.25%;
}

.divider-bottom {
  border-bottom: 3px solid #f4f4f4;
}

.divider-right {
  border-right: 3px solid #f4f4f4;
}
</style>
